
img { max-width: 100%; height: auto; } 
*, :before, :after {box-sizing: border-box;}


.page-wrpper { display: flex;  max-width: 100%; margin: auto; min-height: 100vh; } 
.content-wrapper { width: calc(100% - 110px); padding: 0 0 0 0px; margin: 0; box-shadow: 0 20px 50px 0px #0c0c0c26 inset; background: #fdfdfd; } 
.header-area { background: #0051A6; min-height: 180px; border-radius: 0 0 50px 50px; padding: 15px 5% 80px; display: flex; width: 100%; flex-wrap: wrap; } 


.header-top { display: flex; padding: 0 0 1em; width: 100%; justify-content: space-between; } 
.header-top button {  border: 0; background: none; color: #fff; padding: 0;cursor: pointer;} 
.header-top  .left-content .wrapper{ width: auto; background: #0064af; display: inline-block; padding: 0; border-radius: 100px; overflow: hidden;position: relative;z-index: 1; }
.header-top .left-content .wrapper:before { content: ''; width: 50%; height: 100%; position: absolute; z-index: -1; background: #fff; border-radius: 100px; left: 0; transition: all 0.2s ease 0s; } 
.header-top .left-content button:first-child { color: rgba(0, 81, 166, 1); } 
.header-top .left-content .wrapper[status='active']:before { left: 50%; } 
.header-top .left-content .wrapper[status='active'] button:first-child { color: #fff; } 
.header-top .left-content .wrapper[status='active'] button:last-child { color: rgba(0, 81, 166, 1); } 
.header-top  .left-content button { width: 125px; padding: 10px 10px; }
.header-top  .right-content button { padding: 0 10px; }
.header-top .right-content img { max-width: 32px; border-radius: 100px; margin: 0 5px 0 0; height: 32px;} 
.header-top .right-content { display: flex; align-items: center; height: 36px; }

.managemeal .header-top .left-content .wrapper:before { width: 100%; }

.header-left {width: calc(100% - 300px);} 
.header-right { width: 300px; display: flex; align-items: center;justify-content: flex-end;} 
.filter.item { display: inline-block; position: relative;z-index: 999999; } 
.filter-title { background: #fff; border-radius: 100px; display: inline-block; padding: 10px 20px; color: #7D7D7D;cursor: pointer; } 
.filter-title svg.tabler-icon { color: #0051A6; margin: 0 0 0 6px; } 
.dropdown-filters { position: absolute; background: #fff; z-index: 99; width: 200px; right: 0; top: 54px; border-radius: 10px; border: 1px solid #919eab40;display: none; } 
.dropdown-filters:before { content: ''; display: block; position: absolute; right: 19px; top: -13px; border-width: 0 9px 13px; border-style: solid; border-color: transparent transparent #fff; }
.mantine-Grid-root { margin: 0; }


.imgs-col-container { display: flex; flex-wrap: wrap; padding: 45px 0 0; } 
.imgs-col-container .img-col {position: relative; max-width: 220px; margin: 10px; background: #fff; box-shadow: 0px 10px 13px -5px rgba(0,0,0,0.1); border-radius: 7px; overflow: hidden; display: flex;  justify-content: center; width: 200px; min-height: 200px; padding: 0px; z-index: 0;background: url(/src/images/loader.gif) no-repeat center center #fff; background-size: 65px; } 
.popup.add-group.img-popup  .mantine-InputWrapper-root .mantine-Radio-root input { background: none !important; }  
.imgs-col-container .img-col img {max-height: 200px;}
.manageimg span.delete-cta { position: absolute; right: 10px; top: 10px; z-index: 1; background: #fff; padding: 5px; border-radius: 100px; display: none; width: 40px; height: 40px; justify-content: center; align-items: center; cursor: pointer; } 
.imgs-col-container .img-col:hover  span.delete-cta { display: flex; } 
.imgs-col-container .img-col span.delete-cta:before { content: ''; width: 100%; height: 100%; position: absolute; background: #000; opacity: 0; top: -148%; transition: 0.2s ease-out 0s; right: -150%; border-radius: 8px; } 
.imgs-col-container .img-col:not(.popup-active):hover span.delete-cta:before { top: -10px; opacity: 0.2; right: -10px; width: 240px; height: 240px; }
.manageimg span.delete-cta:not(.popup-active):after { position: absolute; top: 0; left: 0; width: 100%; height: 100%; content: ''; z-index: 3; background: #fff; border-radius: 100px; } 
.manageimg span.delete-cta img { position: relative; z-index: 4; }

.img-col:not(.popup-active) .popup {margin: 0; position: relative; box-shadow: none; width: 100%; height: auto;padding: 0;max-width: 100%;display: flex;align-items: center;justify-content: center;    min-height: auto; } 
.img-col:not(.popup-active) .popup span.close { display: none; }
.imgs-col-container  .img-col:not(.popup-active):hover  span.delete-cta span{display: none;}

.imgs-col-container .img-col.popup-active { z-index: 1; }
.popup-active .popup { width: 980px; height: 640px;max-height: 75vh; max-width: 95%; display: flex; align-items: center; justify-content: center; } 
.popup-active .popup span.close { right: 10px; top: 10px;    background: #fff; } 
.popup-active .popup span.delete-cta { display: block; top: auto; bottom: 10px; right: 20px; width: auto; height: auto;padding: 0 !important; background: none; } 
.popup-active .popup span.delete-cta img { display: none; } 
.popup-active .popup span.delete-cta:before, .popup-active .popup span.delete-cta:after { display: none; } 
.popup-active .popup span.delete-cta span.cta-name { background: #0051A6; width: 150px; display: flex; height: 42px; justify-content: center; align-items: center; color: #fff; border-radius: 10px; }

.imgs-col-container .img-col.popup-active:before { width: 100vw; height: 100vh; content: ''; position: fixed; left: 0; top: 0; z-index: 9; background: #0000000d; }
.imgs-col-container .img-col.popup-active .popup > img{max-height: 100%;}

.Dashboard.manageimg .header-area { padding-bottom: 30px; }

.popup.img-add form { max-width: 380px; margin: auto; width: 100%; }
.popup.img-add form .upload-img button { border: 1px dashed #ccc; height: 55px; }
.popup-active .popup > img { max-height: 100%; }

@media screen and (max-width:991px) {
    
    .content-wrapper { width: calc(100% - 76px); } 
    .header-area { padding: 30px 15px 80px; } 
    .main-content { padding: 0 15px; }
    .mantine-Grid-col.mantine-upafdc { flex-basis: 100%; max-width: 100%; margin: 0; padding: 0; }
}
@media screen and (max-width:768px) {
.header-left { width: 100%; padding: 0 0 10px; } 
.header-right { width: 100%; } 
.filter.item { padding: 0; } 
.filter.item:not(:last-child) { margin: 0 5px 0 0; }
}