.loader-container {
    width: 100%; height: 75%; display: flex; justify-content: center; align-items: center; position: absolute; background: rgba(255, 255, 255, 0.7); z-index: 1; left: 0;
}

.spinner {
    width: 56px;
    height: 56px;
    border: 3px solid;
    border-color: #0467a4 #0065a3 #0467a4  transparent;
    border-radius: 50%;
    animation: spin-anim 0.9s linear infinite;
}
@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}