.login-wrapper { display: flex; width: 100%; height: 100vh; } 
.login-wrapper .leftside { display: flex; justify-content: center; align-items: flex-start; padding: 40px 5%; width: 40%;flex-wrap: wrap;align-content: flex-start; background: url(./loginbg.svg) no-repeat -40% 115%;;background-size: 150% auto;}
 .rightside.admin { width: 60%; background: url(./loginpage-01.jpg) no-repeat center center; background-size: cover; }

 .logo { text-align: center; } 
 .logo img { max-width: 180px; } 
 .logo h3 { margin: 0 0 2em; font-weight: 500; color: #000; } 
 
 form#login { box-shadow: 0px 95px 38px rgba(0, 0, 0, 0.01), 0px 53px 32px rgba(0, 0, 0, 0.03), 0px 24px 24px rgba(0, 0, 0, 0.05), 0px 6px 13px rgba(0, 0, 0, 0.06), 0px 0px 0px rgba(0, 0, 0, 0.06) !important; border-radius: 20px !important; width: 100% !important; max-width: 400px !important; padding: 40px 5% 30px; } 
 div#userfront-mlnlddr { width: 100%; } 
 [mlnlddr] #userfront-login-form[data-v-283fc217] h2 { display: none; } [mlnlddr] .uf-muted[data-v-0d5633d1] { padding: 10px 0 0; color: #7f7f7f; } 
.uf-links { display: none !important; } 
 .uf-branding.uf-muted { display: none !important; } 
 [mlnlddr] .el-input--suffix .el-input__inner { border-width: 0 0 1px; padding-left: 0; border-color: #919EAB52; border-radius: 0; } 
.submit-btn {color: #fff;  padding: 10px 0;height: auto;line-height: normal; font-weight: 700;font-size: 16px; letter-spacing: 0.6pt; background: #0051A6; border: 0 none; border-radius: 5px; font-weight: 400 !important; width: auto; margin: 25px auto 0; display: block; min-width: 110px; }
form#login input:not([type="submit"]) { border: 0; border-radius: 0; border-bottom: 1px solid rgba(145, 158, 171, 0.32); padding: 0; font-size: 16px; } 
form#login .mantine-PasswordInput-input { border: 0; border-bottom: 1px solid rgba(145, 158, 171, 0.32); border-radius: 0; } 
form#login input#password { border-bottom: 0; }
div.item{padding:  0 0 15px;}
div.item.error { color: #fff; background: #fa5252c7; margin: -30px -10px 10px; border-radius: 6px; padding: 10px 15px; font-size: 14px; }
div.item.status { color: #fff; background: #fa5252c7; margin: -30px -10px 10px; border-radius: 6px; padding: 10px 15px; font-size: 14px; }

@media all and (max-width:991px){
    .login-wrapper .leftside { width: 70%; }
}

@media all and (max-width:768px){
    .login-wrapper .leftside { position: relative; z-index: 2; width: 450px; background: none; height: auto; margin: auto; } 
    .rightside { position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 1; } 
    form#login { background: #fff; } 
    .rightside:before { content: ''; width: 100%; height: 100%; background: linear-gradient(185deg, white 50%, transparent); position: absolute; opacity: 0.9; }
}