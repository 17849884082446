
img { max-width: 100%; height: auto; } 
*, :before, :after {box-sizing: border-box;}


.page-wrpper { display: flex;  max-width: 100%; margin: auto; min-height: 100vh; } 
.content-wrapper { width: calc(100% - 110px); padding: 0 0 0 0px; margin: 0; box-shadow: 0 20px 50px 0px #0c0c0c26 inset; background: #fdfdfd; } 
.header-area { background: #0051A6; min-height: 180px; border-radius: 0 0 50px 50px; padding: 15px 5% 80px; display: flex; width: 100%; flex-wrap: wrap; } 


.header-top { display: flex; padding: 0 0 1em; width: 100%; justify-content: space-between; } 
.header-top button {  border: 0; background: none; color: #fff; padding: 0;cursor: pointer;} 
.header-top  .left-content .wrapper{ width: auto; background: #0064af; display: inline-block; padding: 0; border-radius: 100px; overflow: hidden;position: relative;z-index: 1; }
.header-top .left-content .wrapper:before { content: ''; width: 50%; height: 100%; position: absolute; z-index: -1; background: #fff; border-radius: 100px; left: 0; transition: all 0.2s ease 0s; } 
.header-top .left-content button:first-child { color: rgba(0, 81, 166, 1); } 
.header-top .left-content .wrapper[status='active']:before { left: 50%; } 
.header-top .left-content .wrapper[status='active'] button:first-child { color: #fff; } 
.header-top .left-content .wrapper[status='active'] button:last-child { color: rgba(0, 81, 166, 1); } 
.header-top  .left-content button { width: 125px; padding: 10px 10px; }
.header-top  .right-content button { padding: 0 10px; }
.header-top .right-content img { max-width: 32px; border-radius: 100px; margin: 0 5px 0 0; height: 32px;} 
.header-top .right-content { display: flex; align-items: center; height: 36px; }

.managemeal .header-top .left-content .wrapper:before { width: 100%; }

.header-left {width: calc(100% - 300px);} 
.header-right { width: 300px; display: flex; align-items: center;justify-content: flex-end;} 
.filter.item { display: inline-block; position: relative;z-index: 999999; } 
.filter-title { background: #fff; border-radius: 100px; display: inline-block; padding: 10px 20px; color: #7D7D7D;cursor: pointer; } 
.filter-title svg.tabler-icon { color: #0051A6; margin: 0 0 0 6px; } 
.dropdown-filters { position: absolute; background: #fff; z-index: 99; width: 200px; right: 0; top: 54px; border-radius: 10px; border: 1px solid #919eab40;display: none; } 
.dropdown-filters:before { content: ''; display: block; position: absolute; right: 19px; top: -13px; border-width: 0 9px 13px; border-style: solid; border-color: transparent transparent #fff; }
.mantine-Grid-root { margin: 0; }
.dropdown-item:not(:last-child) { border-bottom: 1px solid rgba(145, 158, 171, 0.32); } 
.dropdown-item .collapsible-filter-title { padding: 10px 15px; position: relative;cursor: pointer; } 
.dropdown-item  .collapsible { display: none; }
.dropdown-item  .collapsible .mantine-Checkbox-root{ border-bottom: 1px solid rgba(145, 158, 171, 0.32);padding: 10px 15px; }
.dropdown-item  .collapsible .mantine-Checkbox-root:last-child{border-bottom: 0 none;}
.filter-title[status='current'] + .dropdown-filters {display: block;}

.datefilter input[type="text"] { border: 0; outline: none !important; z-index: 2; position: relative; background: transparent; width: 100px; } 
.datefilter .filter-title { position: relative; } 
.datefilter svg.tabler-icon { position: absolute; right: 15px; top: 10px; } 
.filter.item:not(:last-child) { margin: 0 20px 0 0; }
.datefilter .react-datepicker__close-icon { right: -9px; z-index: 3; background: #fff; }
.datefilter .react-datepicker__close-icon::after {background: red;font-size: 18px;line-height: 11px;}
.collapsible-filter-title[status*='current'] + .collapsible {display: block;}

.dropdown-item .collapsible-filter-title:before { content: ""; width: 8px; height: 8px; border-width: 0 0 1px 1px; border-style: solid; display: block; position: absolute; transform: rotate(-45deg); right: 17px; top: 16px; } 
.dropdown-item .collapsible-filter-title[status='current']:before { transform: rotate(135deg); top: 21px; }

.main-content { padding: 0 15px 0 5%; max-width: 1800px; margin: auto;} 

.main-content .mantine-ScrollArea-root { margin: -4em  0 2em; position: relative; } 
tr.mantine-1avyp1d { margin: 0 0 20px; display: flex; padding: 15px 10px; border-radius: 10px; border: 0 none; box-shadow: 0px 68px 41px rgba(0, 0, 0, 0.01), 0px 30px 30px rgba(0, 0, 0, 0.02), 0px 8px 17px rgba(0, 0, 0, 0.02), 0px 0px 0px rgba(0, 0, 0, 0.02); border-left: 12px solid #FF6100; } 
table.mantine-Table-root { border-collapse: collapse; overflow: visible; } 
.mantine-a5zxts { overflow: visible; } 
.mantine-ScrollArea-root { overflow: visible; } 
.mantine-b6zkvl.mantine-ScrollArea-viewport { border-radius: 10px; } 
tr.mantine-1avyp1d:nth-child(odd) { border-color: #00DCA7; }
.mantine-a5zxts.mantine-a5zxts tbody tr td { border: 0;overflow: hidden; text-overflow: ellipsis;font-weight: 500; }
.mantine-Table-root td:before { content: ''; width: 100%; color: #7D7D7D; font-size: 12px; display: inline-block; font-weight: 400; padding: 0 0 4px; } 
td.category_pics img { position: static; width: auto; height: auto; padding: 0; max-width: 76px;max-height: 76px; min-width: auto; } 
.mantine-a5zxts.mantine-a5zxts tbody tr td.category_pics { padding: 0; }
td.item_name:before { content: "Name"; } 
td.category:before { content: "Category"; } 
td.brand:before { content: "Brand"; } 
td.calories_per_serving:before { content: "Calories per serving"; } 
td.serving_size:before { content: "Serving size"; } 
td.protein:before { content: "Protein"; } 
td.fats:before { content: "Fat"; } 
td.carbs:before { content: "Carbs"; } 
td.fibre:before { content: "Fibre"; }
.mantine-a5zxts.mantine-a5zxts tbody tr td.height:after { content: ' CMS'; font-weight: 400; font-size: 12px; color: #7D7D7D; } 
.mantine-Table-root td:after { color: #7d7d7d; font-size: 12px; } 
.mantine-Table-root td.weight:after { content: 'Ibs'; content: "  Ibs"; }

td.cta span { cursor: pointer; width: 55px; height: 55px; display: flex; justify-content: center; border: 1px solid #9bf7e7; border-radius: 100px; margin: -15px 0 0 0; }
td.cta.delete span{border-color: #ffb3b0;}
td.cta span img{width: 25px;}

.Dashboard input.mantine-Input-input { border-radius: 25px; border: 0 none; box-shadow: none; height: 40px; text-align: left;}
.mantine-Grid-col { padding-left: 0; }
td.category_pics { text-align: center; padding-right: 40px !important;}

.user-link a { position: absolute; right: 20px; top: 0; bottom: 0; width: 55px; height: 55px; border: 1px solid #00dca770; border-radius: 100px; margin: auto; display: flex; justify-content: center; align-items: center; color: #00DCA7; transition: all 0.1s ease-in 0s; box-shadow: 0px 0px 0px -2px #00403138; background: #fff; } 
.user-link a:hover { color: #fff; background: #00DCA7; border-color: #00DCA7; right: 15px; box-shadow: -25px 0px 15px 0 #00403138; }
.cta-wrapper { position: absolute; z-index: 99; background: #fff; height: 75px; padding: 25px; right: 1px; min-width: 100px; }
.mantine-a5zxts.mantine-a5zxts tbody tr td.user-link {display: flex;align-items: center;}

h1 { color: #fff; font-weight: 600; margin: 0 0 0.6em; } 


.popup { position: fixed; z-index: 101; width: 450px; height: 230px; background: #fff; top: 0; bottom: 0; left: 0; right: 0; margin: auto; padding: 10px 20px 20px; max-width: 95%; border-radius: 12px; box-shadow: 0 0 100px rgba(0,0,0,0.2);   height: -webkit-fit-content;height: -moz-fit-content;height: fit-content;min-height: 450px;} 
.poup-bg { position: fixed; z-index: 100; background: #000; width: 100%; height: 100%; left: 0; top: 0; opacity: 0.2; } 
.popup .popup-titlebar { position: relative; display: flex; border-bottom: 1px solid rgba(145, 158, 171, 0.2); padding: 5px 0 12px; margin: 0 0 12px; color: #000; font-size: 15px; font-weight: 500; } 
.popup .popup-titlebar .time { padding: 0 0 0 10px; margin: 0 0 0 10px; border-left: 1px solid rgba(145, 158, 171, 0.2); } 
.popup .subject { padding: 0 0 15px; margin: 0 0 15px; border-bottom: 1px solid rgba(145, 158, 171, 0.2); } 
.popup .subject span.label { font-size: 12px; color: #6a6a6a; letter-spacing: 0.2pt; } 
.popup .subject .sub-text { display: inline-block; width: 100%; padding: 5px 0 0; font-weight: 500; letter-spacing: 0.2pt; } 
.popup .meeting-link { display: inline-block; width: 100%; } 
.popup .meeting-link span.label { font-size: 14px; color: #000; letter-spacing: 0.2pt; font-weight: 500; } 
.popup .meeting-link a.link-text { display: block; width: 100%; color: #2A3DE8; font-weight: 500; padding: 5px 0 0; font-size: 14px; }
.popup span.close {z-index: 1; position: absolute; right: -10px; top: 0; width: 30px; height: 30px; cursor: pointer; } 
.popup span.close:before, .popup span.close:after {z-index: -1; content: ''; width: 18px; height: 2px; background: #000; position: absolute; top: 0; left: 0; transform: rotate(45deg); bottom: 0; margin: auto; right: 0; } 
.popup span.close:after { transform: rotate(-45deg); }


.pager button.mantine-UnstyledButton-root[data-hidden='disabled'] { display: none;pointer-events: none; } 
.pager button.mantine-UnstyledButton-root[data-disbaled='disabled'] { cursor: default; opacity: 0.7; color: #999;pointer-events: none; } 
.pager button.mantine-UnstyledButton-root { cursor: pointer; margin: 0 2px 0; border: 1px solid #e0e0e0; background: #fff; display: inline-flex; height: 28px; width: 28px; align-items: center; justify-content: center; border-radius: 5px; } 
.pager .mantine-Group-root.mantine-kn8vaf { display: flex; align-items: flex-start; padding: 0 10px 20px; justify-content: flex-end; } 
.pager button.mantine-UnstyledButton-root.active { background: #0063a7; color: #fff; border-color: #0063a7; }

.mantine-ooem79.pager { display: flex; justify-content: space-between; margin: 0 0 4em; } 
.mantine-ooem79.pager .mantine-NativeSelect-root { display: flex; align-items: center; } 
.mantine-ooem79.pager .mantine-NativeSelect-root label { margin: 0 10px 0 0; }

.managemeal .popup.add-group .mantine-InputWrapper-label {color: #656565;font-size: 13px; font-weight: 400; margin: 0px 0 -6px; padding: 0; display: block; position: relative; z-index: 1; } 
.managemeal .popup.add-group .mantine-InputWrapper-root.two-col { margin: 0 0 15px; }
.managemeal .popup.add-group .mantine-InputWrapper-root.two-col.invisible { display: none; visibility: hidden; opacity: 0; pointer-events: none; }

.filter-title.add-cta { background: #FF6100; color: #fff; margin: 0 6px 0 0; }


.managemeal .search-field-formatting { display: flex; align-items: center; width: 100%; padding: 15px 0; border-top: 0; border-bottom: 1px solid rgba(145, 158, 171, 0.2); margin: 0 0 25px; } 
.managemeal .search-field-formatting > div { margin: 0 !important; } 
.managemeal .search-field-formatting > div:first-child { margin: 0 0px 0 0 !important; } 
.managemeal .search-field-formatting > div.mantine-InputWrapper-root { width: 75%; } 
.managemeal .popup.add-group  .mantine-Group-root button.mantine-Button-root { width: 100px; height: 40px; } 
.managemeal .popup.add-group .search-field-formatting .mantine-Input-wrapper input { border-radius: 0; border-style: none none solid; padding: 0 6px; }

input[imgsrc] { width: 100px; height: 100px; }
.popup.add-group.delete-group { min-height: 270px; } 
.popup.add-group.delete-group.img-popup { width: 1200px; max-width: 95%;    z-index: 9999999;min-height: 450px; } 
.img-popup .mantine-InputWrapper-root { display: flex; flex-wrap: wrap; gap: 15px; } 
.img-popup  .mantine-InputWrapper-root svg.mantine-Radio-icon { display: none; } 
.popup.add-group.img-popup  .mantine-InputWrapper-root .mantine-Radio-root {background-image: url(/src/images/loader.gif); background-size: 65px; background-repeat: no-repeat; background-position: center center; } 
.popup.add-group.img-popup  .mantine-InputWrapper-root .mantine-Radio-root input { background: none !important; }


.img-select { display: flex; justify-content: center; width: 100%; } 
.img-select .mantine-InputWrapper-root { display: none; } 
.img-select .clickble-area { cursor: pointer; border: 2px dashed #e4e8eb; padding: 5px 15px; width: 280px; text-align: center; color: #000; display: flex; justify-content: center; align-items: center; }
.img-select .clickble-area span.image { width: 50px; height: 50px; display: block; background: url(/src/images/meal1.svg) no-repeat; margin: 0 10px 0 0; }
.img-select .clickble-area span.image.active{background: none;display: flex;}
.img-select .clickble-area span.image.active img{object-fit: contain;}

.mantine-a5zxts.mantine-a5zxts tbody tr td .placeholder { width: 55px; height: 55px; display: block; background:  url(/src/images/placeholder.svg) no-repeat ; background-size: contain; margin: auto; }
.popup.add-group { z-index: 9999999; }

.popup-active { position: relative; z-index: 999999; }
.small-loader { position: relative; z-index: 99999999; width: 100vw; height: 100vh; position: fixed; top: 0; left: 0; bottom: 0; margin: auto; } 
.small-loader .loader-container { z-index: 999999999; background: #ffffff85; height: 100%; width: 100%; margin: auto; left: 0; right: 0; bottom: 0; top: 0; }



@media screen and (max-width:991px) {
    
    .content-wrapper { width: calc(100% - 76px); } 
    .header-area { padding: 30px 15px 80px; } 
    .main-content { padding: 0 15px; }
    .mantine-Grid-col.mantine-upafdc { flex-basis: 100%; max-width: 100%; margin: 0; padding: 0; }
}
@media screen and (max-width:768px) {
.header-left { width: 100%; padding: 0 0 10px; } 
.header-right { width: 100%; } 
.filter.item { padding: 0; } 
.filter.item:not(:last-child) { margin: 0 5px 0 0; }
}