
.popup.delete-member { width: 430px; height: 200px; } 
.popup.delete-member .ctas-wrapper { display: flex; justify-content: center; padding: 10px 0 0; } 
.popup.delete-member .ctas-wrapper button { margin: 0 5px; height: 45px; padding: 0 40px; border: 0; background: #ccc; border-radius: 5px; font-size: 18px; font-weight: 500; } 
.popup.delete-member .ctas-wrapper button:first-child { background: #f05e3f; color: #fff; } 
.popup.delete-member .popup-titlebar { border: 0; padding: 40px 0 0px; } 
.popup.delete-member Loader { width: 100%; height: 100%; }
.popup.delete-member .popup-titlebar h3 { font-weight: 400; font-size: 16px; text-align: center; width: 100%; } 
.popup.delete-member .popup-titlebar h3 span.highlighted { font-weight: 600; font-size: 19px; }
.popup.delete-member .loader-container { width: 100%; height: 100%; top: 0; bottom: 0; }
td.removeuser .cta-wrapper {height: 20px;top: 0;bottom: 0;display: flex;align-content: center;color: red;margin: auto;} 


