.Useredit .main-content { display: flex; margin: -8em 0 0; } 
.Useredit .sidebar { width: 240px; padding: 0 40px 0 0; } 
.Useredit .user-pic { width: 200px; background: #0088db; height: 200px; border-radius: 100%; } 
.user-pic-update { padding: 1em 0 0.8em; display: flex; justify-content: center; max-width: 200px; margin: 0 auto 1em; border-bottom: 1px solid #ccc; } 
.Useredit .sidebar button { background: none; padding: 6px 10px; border: 1px solid #0053a6; border-radius: 7px; margin: 0 4px; font-size: 12px; min-width: 70px; color: #0053a6; }
.Useredit .sidebar > button { width: 200px; margin: auto; display: block; } 
.Useredit main { width: 100%; }
.Useredit main h2 { color: #fff; margin: 3em 0; } 
.Useredit .mantine-1avyp1d { max-width: 680px; }
.Useredit .mantine-1avyp1d input { border-width: 0 0 1px 0; border-radius: 0; padding: 0 ; height: 25px; min-height: 25px; } 
.Useredit .mantine-1avyp1d .form-item { padding: 0 0 25px 0; } 

.popup.new-member {width: 570px;border-radius: 11px;box-shadow: 0 10px 20px rgb(0 0 0 / 10%);height: 530px; max-height: 70vh;} 
.popup.new-member h3 { text-align: center; width: 100%; margin: 7px 0 7px; font-weight: 600; } 
.Useredit .popup.new-member form { display: block; height: calc(100% - 67px); overflow: auto; } 
.popup.new-member .mantine-1avyp1d button { margin: auto; display: block; }
.Useredit .popup.new-member form .mantine-1avyp1d { height: 100%; }

.msg-wrapper { padding: 15px 19px; border: 1px solid #74cd74; max-width: 800px; margin:  0 0 2em; background: #e5ffe5; }


