

img { max-width: 100%; height: auto; } 
*, :before, :after {box-sizing: border-box;}

body{font-family: 'Montserrat', Arial, Helvetica;font-size: 14px;}

/* .page-wrpper { display: flex; width: 1600px; max-width: 100%; margin: auto; min-height: 100vh; } 
.page-wrpper div#header { width: 100px; height: 100%; padding: 20px 3px; } 
.content-wrapper { width: calc(100% - 110px); padding: 0 0 0 0px; margin: 0; box-shadow: 0 20px 50px 0px #0c0c0c26 inset; background: #fdfdfd; } 
.header-area { background: #0051A6; min-height: 180px; border-radius: 0 0 50px 50px; padding: 30px 5%; } 
div#header a { display: inline-block; padding: 10px 0; border-bottom: 1px solid #919EAB52; box-shadow: 0 1px 0 #fff, 0 2px 0 #919EAB52; }


.main-content { padding: 0 5%; } 

.main-content .mantine-e1wlop { margin: -4em  0 2em; position: relative; } 
tr.mantine-1avyp1d { margin: 0 0 20px; display: flex; padding: 15px 10px; border-radius: 10px; border: 0 none; box-shadow: 0px 68px 41px rgba(0, 0, 0, 0.01), 0px 30px 30px rgba(0, 0, 0, 0.02), 0px 8px 17px rgba(0, 0, 0, 0.02), 0px 0px 0px rgba(0, 0, 0, 0.02); border-left: 12px solid #FF6100; } 
table.mantine-Table-root { border-collapse: collapse; overflow: visible; } 
.mantine-a5zxts { overflow: visible; } 
.mantine-ScrollArea-root { overflow: visible; } 
.mantine-b6zkvl.mantine-ScrollArea-viewport { overflow: visible !important; } 
tr.mantine-1avyp1d:nth-child(odd) { border-color: #00DCA7; }
.mantine-a5zxts.mantine-a5zxts tbody tr td { border: 0;overflow: hidden; text-overflow: ellipsis;font-weight: 500; }
.mantine-Table-root td:before { content: ''; width: 100%; color: #7D7D7D; font-size: 12px; display: inline-block; font-weight: 400; padding: 0 0 4px; } 
.mantine-Table-root td.first-name:before { content: 'First Name'; } 
.mantine-Table-root td.last-name:before { content: 'Last Name'; } 
.mantine-Table-root td.email:before { content: 'Email'; } 
.mantine-Table-root td.gender:before { content: 'Gender'; } 
.mantine-Table-root td.dob:before { content: 'D.o.B.'; } 
.mantine-Table-root td.glucose-level:before { content: 'Fasting Glucose - @5.30am'; } 
.mantine-Table-root td.goal:before { content: 'Goal'; } 
.mantine-Table-root td.height:before { content: 'Height'; } 
.mantine-Table-root td.weight:before { content: 'Weight'; } 
.mantine-Table-root td.mobile:before { content: 'Contact Number'; } 
.mantine-a5zxts.mantine-a5zxts tbody tr td.height:after { content: ' CMS'; font-weight: 400; font-size: 12px; color: #7D7D7D; } 
.mantine-Table-root td:after { color: #7d7d7d; font-size: 12px; } 
.mantine-Table-root td.weight:after { content: 'Ibs'; content: "  Ibs"; }

input.mantine-Input-input { border-radius: 25px; border: 0 none; box-shadow: none; height: 40px; } */


span.user-name { display: flex; flex-direction: column; } 
span.user-details { display: flex; flex-direction: column; color: #fff; padding: 0 0 0 10px; } 
span.current-user { display: flex; align-items: center; } 
span.user-details span.username { font-weight: 600; font-size: 14px; text-transform: capitalize; } 
span.user-details span.userrole { font-size: 12px; }
span.user-popup { align-self: baseline; padding: 0 0 0 0px; border-left: 1px solid #fff; margin: 3px 0 0 10px; display: flex; }


.right-content .adminlogin { color: #fff; background: #009e5b; text-decoration: none; padding: 10px 20px; margin: 0 30px 0 0; border-radius: 12px; cursor: pointer; }





