.user-title-bar { display: flex; align-items: center; padding: 1em 0; justify-content: space-between; font-size: 21px; font-weight: 600; } 
.user-data-content { display: flex; margin: 1em 0; width: 100%; } 
.user-data-content .left-content { width: 74%; display: flex; flex-wrap: wrap;    align-content: flex-start; } 
#screen1{display: flex; flex-wrap: wrap;    align-content: flex-start;}
.user-data-content .right-content { width: 26%;padding:0 0 0 30px } 
.user-data-content .left-content .col { display: flex; margin: 0 20px 40px 0;width: calc(40% - 20px);padding:15px;box-shadow: 0px 68px 41px rgba(0, 0, 0, 0.01), 0px 30px 30px rgba(0, 0, 0, 0.05); border-radius: 12px; border: 1px solid #f9f9f9; }
.user-data-content .left-content .col.col-55 { width: 60%; margin-right: 0; flex-wrap: wrap; padding: 15px 7.5px; align-content: flex-start;} 

.user-data-content  .col.col-55 .col-wrapper { border: 1px solid rgba(145, 158, 171, 0.32); border-radius: 12px; padding: 8px; height: 48%;width: 100%; }


.user-data-content .col.col-45 .two-col { display: flex; flex-wrap: wrap;align-content: flex-start; } 
.user-data-content .col.col-45 .two-col .item { width: 100%; padding: 0 5px 20px; display: flex;flex-wrap: wrap; } 
.user-data-content .col.col-45 .two-col .item span.icon { padding: 0 10px 0 0; } 
.user-data-content .col .item  span.label { font-weight: 600; font-size: 14px; display: block; padding: 0 0 7px; } 
.user-data-content .col .item  span.text { font-size: 11px; display: block; padding: 5px 0; } 
.user-data-content .col .item .text  strong { font-size: 14px; }
.user-data-content .col .item span.values { font-size: 13px; font-weight: 600; width: 100%; display: block; padding: 0 0 5px; letter-spacing: 0.2pt; }
.user-data-content .col .item span.time { display: inline; font-size: 12px; letter-spacing: 0.2pt; padding: 0 0 0 5px; }
.user-data-content .col .item span.val-text{font-size: 12px;} 

.user-data-content .col.col-55 .col-group { width: calc(28.3% - 15px); margin: 0 7.5px 15px; display: flex; align-content: space-between; } 
.user-data-content .col.col-55 .col-group:not(:first-child) { flex-wrap: wrap; } 
.user-data-content .col.col-55 .col-group:first-child .col-wrapper { height: 100%;width: 100%; }
.user-data-content .col.col-55 .col-group:nth-child(2) .col-wrapper:not(:first-child) { width: 100%; } 
.user-data-content .col.col-55 .col-group:nth-child(2) { justify-content: space-between; }
.user-data-content .col.col-55 .col-group:nth-child(2) .col-wrapper:first-child .item .data { display: flex; flex-wrap: wrap; align-content: space-between; } 
.user-data-content .col.col-55 .col-group:nth-child(2) .col-wrapper:first-child .item .data > * { width: 100%; } 
.user-data-content .col.col-55 .col-group:nth-child(2) .col-wrapper:first-child .item .data .two-col, .user-data-content .col.col-55 .col-group:nth-child(3) .col-wrapper:first-child .item .data .two-col { display: flex; } 
.user-data-content .col.col-55 .col-group:nth-child(2) .col-wrapper:first-child .item .data .two-col .item, .user-data-content .col.col-55 .col-group:nth-child(3) .col-wrapper:first-child .item .data .two-col .item { flex: 0 50%; }
.user-data-content .col.col-55 .col-group:nth-child(3) .col-wrapper { width: 48%; }
.user-data-content .col.col-55 .col-group:nth-child(3) { justify-content: space-between; width: calc(43.3% - 15px);}
.user-data-content .col.col-55 .col-wrapper .two-col { display: flex; } 
.user-data-content .col.col-55 .col-wrapper .two-col .item { width: 50%; }
.user-data-content .col.col-55 .row { width: 100%; }

.user-data-content .col.col-45 .two-col .item .two-col .item { display: block; padding: 0; } 
.user-data-content .col.col-45 .two-col .item .data { width: 100%; }
.user-header { width: 100%; padding: 20px 0; position: relative; margin: 0 0 25px; display: flex; } 
.user-header:before { content: ''; width: calc(100% + 30px); height: 1px; background: rgba(145, 158, 171, 0.32); left: -15px; display: block; position: absolute; bottom: 0; } 
.user-header .user-info .usernamr { display: block; font-weight: 600; color: #000; } 
.user-header span.gender-age { text-transform: capitalize; font-size: 14px; } 
.user-data-content .col.col-45 .two-col .item span.label { font-weight: 400; display: block; width: 100%; padding: 0; } 
.user-data-content .col.col-45 .two-col .item span.text { font-weight: 500; font-size: 14px; } 
.user-data-content .col.col-45 .two-col .item span.text ul { margin: 0; padding: 0 0  0 15px; }

.user-data-content .col .item span.text.date { margin-bottom: 0; padding-bottom: 0; } 

.user-data-content .left-content .col.col-100 { width: 100%; margin: 0 0 40px; padding: 0; box-shadow: none;flex-wrap: wrap; } 
h2 { font-size: 21px; font-weight: 600; margin: 0 0 1em; }
.col.col-100 .col-group.two-col { display: flex; justify-content: space-between; width: 100%;flex-wrap: wrap;} 
.col.col-100 .col-group.two-col .col-wrapper { min-height: 150px; width: calc(50% - 7.5px); border: 1px solid rgba(145, 158, 171, 0.32); border-radius: 12px; padding: 20px; background: #fff;margin-bottom: 35px; }


.total-cal.dark-text { color: #000; font-size: 16px;font-weight: 600; } 
.total-cal.dark-text span { display: block; padding: 3px 0 10px;font-weight: 400;font-size: 14px; }
.col-wrapper{position: relative; }
.col-wrapper .loader-container{height: 100%;}
.col-wrapper .spinner{
    width: 24px;height: 24px;border-width: 2px;
}
.title.meal { font-size: 16px; font-weight: 600; }

.user-title-bar a[href*='/user?user='] svg.tabler-icon { color: #00DCA7; align-self: center; display: block; width: 18px; height: 18px; margin: auto; } 
.user-title-bar a[href*='/user?user='] { border: 1px solid #0051A6; width: 25px; height: 25px; display: inline-flex; justify-content: center; align-items: center; border-radius: 100px; align-content: center; padding: 0; } 
.user-title-bar > div:first-child { display: flex; align-items: center;    white-space: nowrap;
    word-break: keep-all;
    word-wrap: normal;
    text-overflow: ellipsis; } 
.user-title-bar a.prev-arrow { margin: 0 35px -4px 0; } 
.user-title-bar a.next-arrow { margin: 0 0 -4px 35px; }

.profilepage .header-area { min-height: 125px; padding: 15px 5% 30px; }
.profilepage  .datefilter input { vertical-align: middle; line-height: unset; margin: -8px 0 0; }

.profilepage .main-content > .loader-container { height: 100%; } 
.profilepage .main-content { position: relative; } 
.profilepage .main-content > .loader-container .spinner { position: absolute; top: calc((100vh - 120px) / 2); }

div.mantine-Select-dropdown *,div.mantine-MultiSelect-dropdown *{margin:0 !important}
.mantine-InputWrapper-root.mantine-MultiSelect-root { width: 200px; height: auto; }
.mantine-MultiSelect-values .mantine-MultiSelect-defaultValue { padding: 0 0 0 4px; margin: 1px; font-weight: 500; } 
.mantine-MultiSelect-values .mantine-MultiSelect-defaultValue button.mantine-UnstyledButton-root { padding: 0; margin: 0; width: 17px; min-width: 17px; } 
.mantine-MultiSelect-input { padding-left: 6px; padding-right: 2em; }
.icon img { max-width: 25px; max-height: 25px; height: auto; width: auto; } 
h2 img { max-width: 25px; max-height: 25px; height: auto; width: auto; margin: 0 10px 0  0; }

.chartlabel { text-align: center; margin: 0; padding: 0 0 0 10%; color: #656565; }

.dayscroller { width: 100%; display: flex; justify-content: center; margin: 0 0 20px;flex-wrap: wrap; } 
.exercise  .dayscroller svg { stroke: #c2c2c2; width: 25px; } 
.exercise .dayscroller .active svg { stroke: #00DCA7; cursor: pointer; }
.dayscroller span { padding: 0 5px; }
.currentweek-cta { display: flex; width: 100%; justify-content: flex-end; } 
.currentweek-cta span { font-size: 14px; display: flex; align-items: center;cursor: pointer; } 
.currentweek-cta span:before { width: 10px; height: 10px; border-radius: 100px; background: #FF6100; display: block; content: ''; margin: 0 7px 0 0; }

.user-data-content .col.col-55 .col-group.percent-height {flex-wrap: wrap;}
.user-data-content .col.col-55 .col-group.percent-height:first-child  .col-wrapper { height: 48%;margin: 0 0 10px; }

input.mantine-Select-input[readonly] { max-width: 138px; text-align: center; }

.col-wrapper > .total-cal.dark-text { padding: 0 0 30px; } 
.col-wrapper.exercise > .filter-wrapper { padding: 0 0 20px; } 
.exercise > .total-cal { padding: 0 0 20px; }
.spinner-wrapper{position: relative;min-width: 200px;height: 200px;width: 100%;}

.recharts-responsive-container { max-width: 100%; }

.col-wrapper.glycemic .mantine-Select-root input.mantine-Select-input[readonly] { max-width: none; text-align: left; border: 0; }
.user-data-content .col.col-55 .col-wrapper{min-height: 190px;margin:0 0 10px}

.page-wrpper.profilepage .filter.item { display: flex; align-items: center;    padding: 0 0 0 10px;
    white-space: nowrap; } 
.page-wrpper.profilepage .weekset { display: flex; font-size: 14px; border-radius: 30px; border: 1px solid #ccc; padding: 5px 15px 5px 15px; align-content: baseline; align-items: center; } 
.page-wrpper.profilepage .weekset span.week.start { display: inline-block; padding: 0 2px; } 
.page-wrpper.profilepage .weekset span.week.previos { color: #00dfad; line-height: 10px; margin: 0 8px 0 0; cursor: pointer;} 
.page-wrpper.profilepage .weekset svg.tabler-icon { position: static; }
.page-wrpper.profilepage .weekset span.week.previos.next { margin: 0 0 0 8px; }

.user-data-content .left-content .col.col-55 > div { display: flex; flex-wrap: wrap; align-content: flex-start; width: 100%; flex: 0 0 100%; } 
.user-data-content .left-content .col.col-55 > h2 { width: 100%; font-size: 16px; display: flex; padding: 30px 20px 5px; }

a[download] {
    background: url(../../images/pdf.svg) no-repeat  7px center #fff; position: absolute; right: 0; top: -29px; padding: 3px 5px 3px 24px; background-size: auto 70%; text-decoration: none; font-weight: 600; color: #d91919; border: 1px solid; border-radius: 13px; font-size: 13px;
}

.header-right a[download] { position: static; padding: 6px 20px 6px 35px; border-radius: 6px; border: 0; background-position: 12px center; background-size: 14px auto; font-size: 16px; }

span.collapsible-icon { position: absolute; right: 10px; width: 25px; height: 25px; }
.col-wrapper.appointments h2.show span.collapsible-icon { transform: rotate(180deg); }
.user-data-content .col.col-45 .two-col .item.inline-items > div { flex: 0 50%; } 
.user-data-content .col.col-45 .two-col .item.inline-items > div span.label { font-size: 12px; } 
.user-data-content .col.col-45 .two-col .item.inline-items > div span.text { font-size: 12px; font-weight: 500; }


.user-data-content .right-content .col.col-100 { display: flex; margin: 0 0 40px 0; width: 100%; padding: 0; box-shadow: 0px 68px 41px rgba(0, 0, 0, 0.01), 0px 30px 30px rgba(0, 0, 0, 0.05); border-radius: 12px; border: 1px solid #f9f9f9; } 
.col-wrapper.appointments h2 { font-size: 16px; display: flex; padding: 30px 45px 25px 20px; border-bottom: 1px solid rgba(145, 158, 171, 0.32); width: 100%; } 
.user-data-content .right-content .col.col-100 .col-group { width: 100%; } 
.col-wrapper.appointments h2 .tabler-icon { margin: 0 10px 0 0; color: #0053a6;  cursor: pointer;} 
.col-wrapper.appointments .item { padding: 0 20px 10px; border-bottom: 1px solid rgba(145, 158, 171, 0.32); display: flex; margin: 0 0 15px; } 
.col-wrapper.appointments .item .day { display: inline-flex; width: 40px; border: 1px solid rgba(145, 158, 171, 0.32); height: 40px; border-radius: 5px; margin: 0 20px 0 0; font-size: 13px; padding: 0 2px; text-align: center; } 
.col-wrapper.appointments .item .day[data-status='0-0']{border-color:rgba(0, 220, 167, 1)}
.col-wrapper.appointments .content { display: flex; flex-wrap: wrap; justify-content: space-between; font-size: 14px; width:calc(100% - 60px)} 
.col-wrapper.appointments .content span.label { font-size: 12px; font-weight: 400; color: #656565; } 
.col-wrapper.appointments .content .subject { display: block; padding: 10px 0 10px; width: 100%; } 
.col-wrapper.appointments .content .status { color: #656565; font-size: 12px; justify-content: center; display: flex; } 
.col-wrapper.appointments .content .status span { display: flex; flex-direction: column; justify-content: center; align-items: center; justify-items: center; } 
.col-wrapper.appointments .content .agennd { display: block; width: 100%; padding: 0 0 10px; } 

.col-wrapper.appointments.insights .item.shared-deatils {flex-wrap: wrap;} .col-wrapper.appointments.insights .item.shared-deatils .ele { width: 100%; text-align: left; padding: 3px 0 3px 10px; } .col-wrapper.appointments.insights .item.shared-deatils .ele.name { font-weight: 600; text-transform: capitalize; font-size: 14px; padding: 0 0 5px 10px; } .col-wrapper.appointments.insights .item:last-child { border: 0; }
.col-wrapper.appointments.insights .item.shared-deatils .ele.date { text-align: right; padding: 0; }

#screen2{width: 100%;}

@media screen and (max-width:1180px) {
    .user-data-content .left-content { width: 65%; } 
    .user-data-content .right-content { width: 35%; } 
    .user-data-content .left-content .col.col-45 {width: 100%;margin: 0 0 20px;box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);} 
    .user-data-content .left-content .col.col-55 { width: 100%; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05); } 
    .recharts-wrapper { height: auto !important; }
}
@media screen and (max-width:920px) {
    .user-data-content{flex-wrap: wrap;}
    .user-data-content .left-content { width: 100%; } 
    .user-data-content .right-content { width: 100%;padding: 0; } 
    .col-wrapper.appointments.insights .item { justify-content: space-between; }
}

@media screen and (max-width: 650px) {
    .user-data-content .col.col-55 .col-group { width: calc(48% - 15px); } 
    .user-data-content .col.col-55 .col-group { width: calc(50% - 15px); } 
    .user-data-content .col.col-55 .col-wrapper { height: auto; margin: 0 0 15px;min-height: auto; } 
    .user-data-content .col.col-55 .col-group:nth-child(3) { width: calc(50% - 15px); }

    .user-data-content .col.col-55 .col-group:nth-child(3) + .row { width: calc(50% - 15px); }
     .user-data-content .col.col-55 .col-group:nth-child(3) + .row .col-group { width: 100%; }
}
@media screen and (max-width: 620px) {
    .col.col-100 .col-group.two-col .col-wrapper { width: 100%; }
    .page-wrpper{overflow: hidden;}
    .user-title-bar { flex-wrap: wrap; } 
    .user-title-bar .filter.item.datefilter { width: 100%; display: flex; justify-content: flex-end; padding: 10px 0  0; }
}

@media screen and (max-width: 520px) {
    .user-data-content .left-content .col.col-55 .col-group { width: 100%; }
    .user-data-content .col.col-55 .col-group:nth-child(3){ width: 100%; }
    .user-data-content .col.col-55 .col-group:nth-child(3) + .row { width: 100%; margin: 0 15px 15px 0; }
}

@media screen and (max-width: 450px) {
    .user-data-content .col.col-45 .two-col .item { width: 100%; } 
    .user-data-content .col.col-45 .two-col .item:last-child { padding-bottom: 0; }
}