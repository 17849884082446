
.page-wrpper div#header { width: 100px; height: 100%; padding: 20px 3px;position: sticky; top: 0; } 
div#header a { display: inline-block; padding: 10px 0; border-bottom: 1px solid #919EAB52; box-shadow: 0 1px 0 #fff, 0 2px 0 #919EAB52; }
div#header .backlink{display: none;}
div#header .backlink[status="visible"]{display: block;}
div#header .backlink a { border: 0; box-shadow: none; width: 100%; text-align: center; display: flex; flex-direction: column; justify-content: center; color: #000; text-decoration: none; margin: 25px 0 0; } 
div#header .backlink a svg { margin: 0 auto; color: #00DCA7; }

.board-links { padding: 25px 0 0; } 
div#header .board-links a.board-link {     text-align: center;display: flex; width: 100%; padding: 15px 20px; border: 0; box-shadow: none; text-decoration: none; justify-content: center; flex-wrap: wrap; color: #7D7D7D; font-weight: 500; font-size: 16px; } 
div#header .board-links a.board-link span { display: flex; margin:0 ; height: 35px; }
div#header .board-links a.board-link[activemenu='active'] { color: #0051A6; background: #e2eef6; } 
div#header .board-links a.board-link[activemenu='active'] svg path { stroke: #0051A6; } 
div#header .board-links a.board-link svg path { stroke: #7d7d7d; }
div#header .board-links a.board-link span img{height: 80%; width: auto;}

@media screen and (max-width:991px) {
.page-wrpper div#header { width: 76px; padding: 10px 0; } 
}