.search-field-formatting { padding: 15px 0 0; border-top: 1px dashed #dae0e4; border-bottom: 1px dashed #dbe0e4; margin: 15px 0; } 
.popup.add-group .search-field-formatting .mantine-Input-wrapper input { border: 1px solid #dbe0e4; padding: 0 10px 0 33px; border-radius: 6px; }
.popup.add-group { max-height: 85vh; height: fit-content; overflow: hidden; display: flex; flex-direction: column; width: 520px; height: -webkit-fit-content;height: -moz-fit-content;height: fit-content;min-height: 450px;} 
.popup.add-group .mantine-CheckboxGroup-root { max-height: calc(100% - 190px); display: block; overflow: auto; height: calc(100% - 190px);min-height: 140px; } 
.popup.add-group form { height: 100%; overflow: auto; display: flex; flex-direction: column; } 
.popup.add-group .mantine-CheckboxGroup-root .mantine-Group-root { flex-wrap: wrap; gap: 0; } 
.popup.add-group .mantine-CheckboxGroup-root .mantine-Group-root .mantine-Checkbox-root { width: 100%; margin: 0; padding: 0 0 10px; } 
.popup.add-group .mantine-Input-wrapper input { border-bottom: 1px solid #dbe0e4; border-radius: 0; padding: 0; } 
.popup.add-group span.close { right: 10px; top: 10px;z-index: 1; } 
.popup.add-group .popup-titlebar { text-align: center; justify-content: center; font-weight: 400; } 
.popup.add-group .popup-titlebar h2 { margin: 10px 0; font-weight: 500; } 
.popup.add-group .mantine-InputWrapper-root { margin: 0 0 15px; } 
.popup.add-group .mantine-Group-root { justify-content: center; display: flex; } 
.popup.add-group .mantine-Group-root button.mantine-Button-root { width: 190px; height: 45px; border-radius: 9px; background: #0051a6; font-size: 17px; } 
.mantine-Checkbox-labelWrapper { color: #656565; letter-spacing: 0.4pt; }
.popup.add-group .mantine-InputWrapper-root.two-col { width: 48%; display: inline-block; } 
.two-col-grp { display: flex; justify-content: space-between; flex-wrap: wrap; }
.two-col-grp.two-col-grp > div:first-child .mantine-Input-wrapper input { font-weight: 500; color: #000; border-bottom: 2px solid #000; }

.popup.add-group.delete-group { height: fit-content; width: 520px;    min-height: 270px; } 
.popup.add-group .mantine-Group-root button.mantine-Button-root.cancel-cta { background: none; color: #0051a6; border: 1px solid #0051a6; } 
.popup.add-group .popup-content { padding: 20px 0 20px; border-bottom: 2px solid #eceff1; margin: 0 0 10px; } 
.popup.add-group .popup-content p { margin: 0 0 10px; line-height: 1.6em; }


.mantine-Table-root td.group-name:before { content: "Group Name"; } 
.mantine-Table-root td.members:before { content: "Members"; } 
.mantine-Table-root td.coaches:before { content: "Coaches"; }
.mantine-Table-root td.gp-address:before { content: "Contact Name"; }
.mantine-Table-root td.gp-contact:before { content: "Contact No."; }
.mantine-Table-root td.group-name > span { cursor: pointer; }
.mantine-Table-root td.gp-address{font-size: 12px;}

.main-content.groups-data .cta-wrapper { top: 0; min-width: 270px; display: flex; justify-content: space-between; } 
.main-content.groups-data .cta-wrapper > span { cursor: pointer; display: flex; flex-wrap: wrap; position: relative; padding: 27px 0 0; } 
.main-content.groups-data .cta-wrapper > span svg.tabler-icon { position: absolute; left: 0; right: 0; top: 0; margin: auto; }
 .main-content.groups-data .cta-wrapper span.delete-cta svg path { stroke: #FF0000; } 
 .main-content.groups-data .cta-wrapper span.edit-cta svg path { stroke: #00dfad; } 
.main-content.groups-data .cta-wrapper span.assign-cta svg path {stroke: #0053a6;}

.main-content.groups-data  tr.mantine-1avyp1d{position: relative;}
.popup.add-group form h5 { font-weight: 400; }
.popup.add-group.assign-coach { height: fit-content; }

.popup.add-group .mantine-InputWrapper-root.members-list { max-height: 65%; } 
.popup.add-group .mantine-InputWrapper-root.coach-list { max-height: 25%; } 
.popup.add-group .mantine-InputWrapper-label { margin: 0 0 10px; font-weight: 700; font-size: 14px; color: #000;} 
.mantine-Checkbox-body { width: 100%; } 
.popup.add-group.edit .mantine-Checkbox-inner { display: none; } 
.popup.add-group.edit .mantine-Checkbox-labelWrapper { width: 100%; }
.popup.add-group.edit .mantine-Checkbox-labelWrapper label { color: #000; letter-spacing: 0.2pt; font-size: 14px; padding: 0 120px 0 0; position: relative; cursor: pointer; } 
.popup.add-group.edit  .mantine-Checkbox-labelWrapper label:before { content: 'Remove'; position: absolute; right: 0; color: #ff0000; border-bottom: 1px solid; font-size: 13px; } 
.popup.add-group.edit .mantine-Checkbox-root[data-checked="true"] .mantine-Checkbox-labelWrapper label:before { content: "Restore"; color: blue }
.popup.add-group.edit .mantine-Checkbox-root[data-checked="true"] .mantine-Checkbox-labelWrapper label {color: #ff0000} 
.popup.add-group.edit .mantine-InputWrapper-root { border-bottom: 1px solid #dae0e4; padding: 0 0 5px; }
.popup.add-group.edit .mantine-InputWrapper-root.group-nameedit { border: 0px solid #cccccc !important; padding: 0; } 
.popup.add-group.edit .mantine-InputWrapper-root.group-nameedit label { margin: 0; } 
.popup.add-group.edit .mantine-InputWrapper-root.group-nameedit  .mantine-Input-wrapper { border-bottom: 1px solid #afafaf; padding: 5px 0px 5px; }

.popup.add-group.edit form { overflow: auto; display: flex; flex-direction: column; }

.global-loader .loader-container {width: 100%;height: 100%;top: 0;bottom: 0;margin: auto;left: 0;right: 0;} 
.global-loader { position: fixed; top: 0; left: 0; bottom: 0; right: 0; margin: auto; z-index: 999; width: 55px; height: 55px; }

.error-msg { border: 1px solid #ff000099; padding: 10px 10px 10px 50px; background: url(../../images/error.svg) no-repeat 10px center #ff000009; background-size: 27px;}

.main-content.groups-data .memers-table tr.mantine-1avyp1d { position: static; } 
.main-content.groups-data .memers-table tr.mantine-1avyp1d .cta-wrapper { top: inherit; min-width: inherit; display: inherit; }

.popup.add-group.memrbes-add .mantine-CheckboxGroup-root .mantine-Group-root { max-height: 100%; overflow: auto; } 
.popup.add-group.members-add .mantine-InputWrapper-root.members-list { max-height: fit-content; } 
.popup.add-group.memrbes-add .mantine-InputWrapper-root.members-list { max-height: 100%; height: 100%; } 
.popup.add-group.memrbes-add  form { display: flex; flex-direction: column; overflow: auto;}
.add-members { cursor: pointer; background: #0051a6; color: #fff; display: inline-block; padding: 5px 10px; border-radius: 6px; font-size: 12px; position: relative; top: 25px;width: 112px; text-align: center;align-self: flex-end;z-index: 9;}
.mantine-PasswordInput-input { border: 0; border-bottom: 1px solid #ccc; border-radius: 0; } 
.popup.add-group .mantine-PasswordInput-input input { border: 0; }

table.mantine-Table-root a { text-decoration: none; color: #000; }